.ui.card.product-card.disabled {
    opacity: 0.5;
}

.ui.card.product-card {
    cursor: pointer;
    min-height: fit-content;
}

.ui.card.product-card > .content {
    display: flex;
}

.ui.card.product-card > .content:not(.extra) {
    flex-direction: column;
}

.ui.card.product-card > .content.extra {
    align-items: center;
}

.ui.card.product-card > .content.extra * {
    margin: 0 !important;
}

.ui.card.ui.card.product-card > .content.extra > .ui.header.sub {
    flex: 1;
}

@media screen and (min-width: 751px) {
    .ui.card.product-card {
      min-height: 300px;
    }
}