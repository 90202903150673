body,
html {
	height: 100%;
	width: 100%;
}

#root {
	height: 100%;
	width: 100%;
}

.App {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.page {
	flex: 1;
	padding-top: 7em;
	padding-bottom: 7em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield !important;
}

.error.field > .ui.buttons > .ui.button {
	color: #9f3a38;
	background-color: #fff6f6;
	border: 1px solid #e0b4b4;
}

.error.field > .ui.buttons > .ui.button:not(:first-of-type) {
	border-left: none;
}

.summary .ui.relaxed.list.summary-list:not(.horizontal) > .item:not(:last-child) {
	padding-bottom: 0.8em;
}

.summary .ui.relaxed.list.summary-list:not(.horizontal) > .item:last-child {
	padding-top: 0.8em;
}

.field .buttons .button:first-child {
	border-right: 1px solid #f2f2f2;
}

.ui.grid.form-grid > .column > .fields > .field {
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
}

.ui.grid.form-grid > .column > .field.flex-column {
	display: inline-flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	padding-bottom: 1rem;
}

@media only screen and (max-width: 767px) {
	.ui.grid.form-grid,
	.ui.stackable.grid.form-grid {
		margin-left: -1rem !important;
		margin-right: -1rem !important;
	}

	.ui.grid.form-grid > .column > .field.flex-column {
		padding-bottom: 0;
	}
}

@media screen and (min-width: 768px) {
	.ui.grid.form-grid:not(:last-of-type, .padding) .column {
		padding-bottom: 0;
	}
}

/*Classes Added by Ryan*/

/* .container form {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
  }
  
  .container label {
	display: flex;
	cursor: pointer;
	font-weight: 500;
	position: relative;
	overflow: hidden;
	margin-bottom: 0.375em;
  }
  
  .container  label input {
	position: absolute;
	left: -9999px;
  }
  
  .container label input:checked + span {
	background-color: #1C4DA0;
	color: white;
  }
  
  .container label input:checked + span:before {
	box-shadow: inset 0 0 0 0.4375em #1C4DA0;
  }
  
  .container label span {
	display: flex;
	align-items: center;
	padding: 0.375em 0.75em 0.375em 0.375em;
	border-radius: 99em;
	transition: 0.25s ease;
	color: #414181;
  }
  
  .container label span:hover {
	background-color: #d6d6e5;
  }
  
  .container label span:before {
	display: flex;
	flex-shrink: 0;
	content: "";
	background-color: #fff;
	width: 1.5em;
	height: 1.5em;
	border-radius: 50%;
	margin-right: 0.375em;
	transition: 0.25s ease;
	box-shadow: inset 0 0 0 0.125em #00005c;
  }

  textarea {
	width: 100%;
	height: 150px;
	padding: 12px 20px;
	box-sizing: border-box;
	border: 2px solid #ccc;
	border-radius: 4px;
	background-color: #f8f8f8;
	font-size: 16px;
	resize: none;
  } */
